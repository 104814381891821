import { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './css/procedures.css';
import mfapdf from '../images/mfa.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


function Procedures() {
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [scale, setScale] = useState(1.5);
    const [mfaDisplayed, setMfaDisplayed] = useState(false);

    function onDocumentLoadSuccess() {
        console.log("pdf loaded");
        setPdfLoaded(true);
    }

    useEffect(() => {
        document.title = "Procédures";

        const updateScale = () => {
            const width = window.innerWidth;

            if (width > 1000) {
                setScale(1.5); // Set maximum scale to 1.5 for wide screens
            } else if (width < 600) {
                setScale(0.6); // Set minimum scale to 0.6 for small screens
            } else {
                // Interpolate the scale for widths between 600px and 1000px
                const newScale = 0.6 + (width - 600) * (0.9 / 400); // Adjust the interpolation
                setScale(newScale);
            }
        };

        // Set initial scale
        updateScale();

        // Update scale on window resize
        window.addEventListener('resize', updateScale);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    return (
        <div className="procedures">
            <div className="procedure-title-and-arrow" onClick={() => setMfaDisplayed(!mfaDisplayed)}>
                <h1>MFA</h1>
                <img src="/images/down-arrow.svg" alt="arrow" className={mfaDisplayed ? "inverted-arrow" : "arrow" } />
            </div>
            {
                mfaDisplayed &&
                
                <Document className="pdf-container" file={mfapdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => alert('Error while loading document! ' + error.message)}>
                {
                    !pdfLoaded && <p>Loading...</p>
                }
                {
                    pdfLoaded &&
                    <div>
                        {Array.from(new Array(8), (el, index) => (
                            <Page className="pdf-page" key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} scale={scale} />
                        ))}
                    </div>
                }
            </Document>
            }
            
        </div>
    );
}

export default Procedures;