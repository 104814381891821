import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Home from './components/home.js';
import Navbar from './components/navbar.js';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./components/footer";
import About from "./components/about";
import Contact from "./components/contact";
import Tarifs from "./components/tarifs";
import Cookies from "./components/cookies";
import Procedures from "./components/procedures.js";

function App() {

  const [homeAnim, setHomeAnim] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [itforallAnimDone, setItforallAnimDone] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHomeAnim(true);
    }, 1000);

    //preloading sebalpha.png
    const img = new Image();
    img.src = "/images/sebalpha.png";

    // Cleanup function
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <div className={`app-bg ${homeAnim ? "app-bg-faded" : ""}`} />
      <Router>
        {!menuOpen &&
          <Link to="/" className="home-link">
            <img
              src="/images/Logo_ITforallcropped.png"
              alt="itforall"
              id='itforalllogo'
              className={`itforalllogo ${homeAnim ? "itforalllogo-top" : ""}`}
            />
          </Link>
        }
        <Navbar
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
        <Routes>
          <Route exact path="/" element={<Home
            homeAnim={homeAnim}
            itforallAnimDone={itforallAnimDone}
            setItforallAnimDone={setItforallAnimDone}
          />} />
          <Route path="about" element={
            <About />
          } />
          <Route path="contact" element={
            <Contact />
          } />
          <Route path="tarifs" element={
            <Tarifs />
          } />
          <Route path="cookies" element={
            <Cookies />
          } />
          <Route path="procedures" element={
            <Procedures />
          } />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
