// navbar component

import React from 'react';
import './css/navbar.css';
import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import menu from '../icons/menu.svg';
import { CSSTransition } from 'react-transition-group';


function Navbar(params) {
    const [isNavbarVisible, setIsNavbarVisible] = useState(false);
    const [clientWidth, setClientWidth] = useState(window.innerWidth);
    const [scrollDirection, setScrollDirection] = useState(window.innerWidth > 768 ? 'down' : 'up');
    const prevScrollPos = useRef(window.pageYOffset);

    function linkClicked() {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" })
        }, 50);
    }

    useEffect(() => {
        const handleResize = () => {
            setClientWidth(window.innerWidth);
        };

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const direction = prevScrollPos.current > currentScrollPos ? 'up' : 'down';
            setScrollDirection(direction);
            prevScrollPos.current = currentScrollPos;

            // Check Navbar visibility
            const navbar = document.querySelector('.Navbar');
            if (!navbar) return;
            const navbarHeight = navbar.offsetHeight;
            const navbarTop = navbar.offsetTop;
            const navbarBottom = navbarTop + navbarHeight;
            const windowTop = window.pageYOffset;
            const windowBottom = windowTop + window.innerHeight;
            setIsNavbarVisible(windowBottom > navbarBottom && windowTop < navbarTop);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);

        };
    }, []);

    return (
        clientWidth > 768 ? (
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={true}
                appear={true}
                timeout={2000}
                classNames="Navbar-transition"
            >
                <div className="Navbar">
                    <div className="navbar-container">
                        <div className="navbar-menu">
                            <ul className={`navbar-menu-list ${!isNavbarVisible && scrollDirection === "up" ? "navbar-menu-list-fixed" : ""}`}>
                                {
                                    !isNavbarVisible && scrollDirection === "up" &&
                                    <Link to="/" className="home-link">
                                <img
                                    src="/images/Logo_ITforallcropped.png"
                                    alt="itforall"
                                    id='itforalllogo'
                                    className="itforalllogo-navbar"
                                />
                            </Link>
                                }
                                <li className="navbar-menu-item" onClick={linkClicked}>
                                    <Link to="/" className="navbar-menu-link">Accueil</Link>
                                </li>
                                <li className="navbar-menu-item" onClick={linkClicked}>
                                    <Link to="/about" className="navbar-menu-link">Qui sommes-nous ?</Link>
                                </li>
                                <li className="navbar-menu-item" onClick={linkClicked}>
                                    <Link to="/contact" className="navbar-menu-link">Contact</Link>
                                </li>
                                <li className="navbar-menu-item" onClick={linkClicked}>
                                    <Link to="/tarifs" className="navbar-menu-link">Tarifs</Link>
                                </li>
                                <li className="navbar-menu-item" onClick={linkClicked}>
                                    <Link to="/procedures" className="navbar-menu-link">Procédures</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        )
            :
            (
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={params.menuOpen}
                        timeout={500}
                        classNames="mobile-Navbar-transition"
                    >
                        <div className="mobile-Navbar" onClick={() => params.setMenuOpen(false)}>
                            <Link to="/" className="home-link">
                                <img
                                    src="/images/Logo_ITforallcropped.png"
                                    alt="itforall"
                                    id='itforalllogo'
                                    className="itforalllogo-mobile-navbar"
                                />
                            </Link>
                            <div className="mobile-navbar-container">
                                <div className="mobile-navbar-menu">
                                    <ul className="mobile-navbar-menu-list">
                                        <li className="mobile-navbar-menu-item" onClick={linkClicked}>
                                            <Link to="/" className="mobile-navbar-menu-link">Accueil</Link>
                                        </li>
                                        <li className="mobile-navbar-menu-item" onClick={linkClicked}>
                                            <Link to="/about" className="mobile-navbar-menu-link">Qui sommes-nous ?</Link>
                                        </li>
                                        <li className="mobile-navbar-menu-item" onClick={linkClicked}>
                                            <Link to="/contact" className="mobile-navbar-menu-link">Contact</Link>
                                        </li>
                                        <li className="mobile-navbar-menu-item" onClick={linkClicked}>
                                            <Link to="/tarifs" className="mobile-navbar-menu-link">Tarifs</Link>
                                        </li>
                                        <li className="mobile-navbar-menu-item" onClick={linkClicked}>
                                            <Link to="/procedures" className="mobile-navbar-menu-link">Procédures</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={!params.menuOpen && scrollDirection === 'up'}
                        timeout={500}
                        appear={true}
                        classNames="menu-icon-transition"
                    >
                        <img src={menu} alt="menu" className="menu-icon" onClick={() => params.setMenuOpen(true)} />
                    </CSSTransition >
                </div>
            )
    );
}

export default Navbar;